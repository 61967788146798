import { useState, useEffect } from 'react'

import { useLead } from '@itau-loans-www/shopping/src/hooks'
import { http } from '@itau-loans-www/shopping/src/services'
import analytics from '@mobi/libraries/analytics'
import { goTo, cleanWhitespaces } from 'utils'

const usePhone = () => {
  const [isButtonLoading, setButtonLoading] = useState(false)

  const {
    leadData: { name, email, cpf, vwoVariant },
    changeLeadData
  } = useLead()

  useEffect(() => {
    changeLeadData({
      previousStep: '/proposta/email'
    })
    analytics.track('pageLoad', {
      currentPage: 'formulario-dados-complementares-telefone',
      customLayer: { variant: vwoVariant }
    })
  }, [])

  const onSubmit = async ({ phone }) => {
    setButtonLoading(true)

    changeLeadData({
      phone,
      previousStep: '/proposta/telefone'
    })

    const formData = {
      cpf,
      name: cleanWhitespaces(name),
      email,
      phone
    }

    analytics.track('buttonClick', {
      currentPage: 'formulario-dados-complementares-telefone',
      detail: 'click-step-basic'
    })

    return http.proposal
      .newCreateLead(formData)
      .then(({ next_step }) => {
        if (next_step === 'desired-loan-data') {
          changeLeadData({ variant: 'A' })
        }

        goTo(next_step)
      })
      .catch((err) => {
        changeLeadData({
          previousStep: '/proposta/email'
        })
        console.log({ err })
        setButtonLoading(false)

        if (!cpf || !name || !email) {
          goTo('home')
        }
      })
  }

  return {
    onSubmit,
    isButtonLoading
  }
}

export default usePhone
