import { useForm } from 'react-hook-form'

import {
  Navigation,
  Input,
  If,
  Shimmer
} from '@itau-loans-www/shopping/src/components'
import { useLead } from '@itau-loans-www/shopping/src/hooks'
import ProposalLayout from 'layouts/Proposal'

import usePhone from './hooks'

const Phone = ({ pageContext }) => {
  const {
    leadData: { phone }
  } = useLead()

  const initialValues = {
    phone: phone ?? ''
  }

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })

  const { onSubmit, isButtonLoading } = usePhone()

  return (
    <ProposalLayout pageContext={pageContext}>
      <If
        condition={isButtonLoading}
        renderIf={
          <>
            <Shimmer
              margin="10px 0 32px 0"
              widthDesktop="80%"
              height="64px"
              heightDesktop="32px"
            />
            <Shimmer margin="0 0 20px 0" height="70px" />
            <Shimmer
              margin="350px 0 0 0"
              marginDesktop="300px 0 0 0"
              widthDesktop="50%"
              height="50px"
            />
          </>
        }
        renderElse={
          <>
            <h1 className="proposal-title --mt">
              {'E o número do seu celular?'}
            </h1>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <Input.Cellphone
                label="Celular"
                id="phone"
                data-cy="cy-input-phone"
                name="phone"
                mask="phone"
                autoComplete="on"
                control={control}
              />

              <Navigation
                submit
                nextStep
                isNextButtonDisabled={!isValid}
                nextButtonId={'basic-data-next-btn'}
                dataCy="cy-btn-next"
                nextButtonText="Continuar simulação"
              />
            </form>
          </>
        }
      />
    </ProposalLayout>
  )
}

export default Phone
